import * as React from 'react';

import type { OperationalTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';

import { Observed } from './observed';
import { ObserverContext } from './observer';

export type InViewTrackingEvent = Omit<
  OperationalTrackingEvent,
  'event' | 'type' | 'immediately'
>;

type InViewTrackingProps = {
  data: InViewTrackingEvent;
  onEnter?: () => void;
  onLeave?: () => void;
  renderAs?: React.ElementType;
  className?: string;
};

const InViewTracking: React.FC<
  React.PropsWithChildren<InViewTrackingProps>
> = ({ data, onEnter, onLeave, children, ...props }) => {
  const { page } = React.useContext(ObserverContext);
  const { track } = useTracking<OperationalTrackingEvent>(page);

  return (
    <Observed
      {...props}
      onEnter={() => {
        track({ ...data, type: 'operational', event: 'entered_viewport' });
        if (onEnter) onEnter();
      }}
      onLeave={() => {
        track({ ...data, type: 'operational', event: 'left_viewport' });
        if (onLeave) onLeave();
      }}
    >
      {children}
    </Observed>
  );
};

export { InViewTracking };
